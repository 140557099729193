/* You can add global styles to this file, and also import other style files */

html, body { height: 100%;   background-color: #e0e0e0;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.grecaptcha-badge {
    visibility: hidden;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #cecece;
  border-radius: 4px;
}
